"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
export const HtmlHead = () => {
  // List mapping urls to page titles, in priority order:
  //  The first item in this list that matches the start of the page's location
  //  will determine the page's title
  const uuidRegExp = "[0-9a-z-]+?";
  const urlToTitle = {
    "/profile": "User Preferences",
    ["/dashboard/payapps?/" + uuidRegExp + "/issues"]: "Pay App Issues",
    ["/dashboard/payapps?/" + uuidRegExp + "/details"]: "Pay App Details",
    ["/dashboard/payapps/" + uuidRegExp + "/edit"]: "Pay App Details",
    ["/dashboard/payapp/" + uuidRegExp + "/" + uuidRegExp]: "Pay App Forms",
    "/dashboard/payapps?": "Pay Apps",
    "/dashboard/cashflow": "Cash Flow",
    "/dashboard/transfers": "Bank Transfers",
    "/dashboard": "",
    "/agency/tasks/mine": "Team Tasks",
    ["/agency/tasks/" + uuidRegExp]: "Status Board",
    ["/agency/payapp/" + uuidRegExp + "/review"]: "Review Pay App",
    ["/agency/payapp/" + uuidRegExp]: "Pay App Details",
    "/agency/payapp": "Pay Apps",
    "/agency/issues/mine": "My Issues",
    "/agency/issues/team": "Team Issues",
    "/agency/issues/watched": "Watched Issues",
    "/agency/issues/all": "All Issues",
    "/agency/settings/teams": "Team Management",
    "/agency/settings": "Agency Settings",
    "/admin/users": "Users",
    "/admin/contractors": "Contractors",
    "/admin/agencies": "Agencies",
    "/admin/payapps": "Admin Payapps",
    "/admin/forms": "Forms",
    "/admin/notifications": "Notifications",
    "/admin/banking/payments/contractor": "Contractor Payments",
    "/admin/banking/payments/agency": "Agency Payments",
    "/admin/banking/auditor": "Ledger",
    "/admin/banking/ledger": "Ledger",
    "/admin/banking/accounts": "Bank Accounts",
    "/admin/banking": "Bank Transfers",
    "/admin": "Leads"
  };
  const [titleSuffix, setTitleSuffix] = useState<string | undefined>("");
  const pathname = usePathname();
  useEffect(() => {
    const pathname = new URL(window.location.href).pathname;
    setTitleSuffix(Object.entries(urlToTitle).find(([regexp, val]) => new RegExp(regexp).test(pathname))?.[1]);
  }, [pathname]);
  return <title data-sentry-component="HtmlHead" data-sentry-source-file="HtmlHead.tsx">{titleSuffix ? `${titleSuffix} | Minca` : "Minca"}</title>;
};